// extracted by mini-css-extract-plugin
export var column = "StatesDashboardOptions__column__zAMWm";
export var flex = "StatesDashboardOptions__flex__nXPFe";
export var flexColumn = "StatesDashboardOptions__flexColumn__UHZ5l";
export var gap1 = "StatesDashboardOptions__gap1__u90Gy";
export var gap2 = "StatesDashboardOptions__gap2__BvUdf";
export var gap3 = "StatesDashboardOptions__gap3__wY61N";
export var gap4 = "StatesDashboardOptions__gap4__U1_L1";
export var gap5 = "StatesDashboardOptions__gap5__NRovM";
export var popoverBody = "StatesDashboardOptions__popoverBody__c0KGQ";
export var popoverBodyLink = "StatesDashboardOptions__popoverBodyLink__TAJWh";
export var popoverBodyLinkIcon = "StatesDashboardOptions__popoverBodyLinkIcon__AXYCa";
export var row = "StatesDashboardOptions__row__AE84Q";